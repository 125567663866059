import { createApp } from "vue";
import App from "./App.vue";
import { loadFonts } from "./plugins/webfontloader";
import vuetify from "./plugins/vuetify";
import router from "./router";
import VueAxios from "vue-axios";
import api from "@/api/api";
import store from "./store/index";
import Shimmer from "vue3-shimmer";
import VueCryptojs from "vue-cryptojs";
import i18n from "./localization/localization";

loadFonts();

createApp(App)
  .use(i18n)
  .use(vuetify)
  .use(router)
  .use(store)
  .use(VueAxios, api)
  .use(Shimmer)
  .use(VueCryptojs)
  .mount("#app");

import "../node_modules/vuetify/dist/vuetify.min.css";
import Hotjar from "@hotjar/browser";

const siteId = process.env.VUE_APP_HOTJAR_SITE_ID;
const hotjarVersion = process.env.VUE_APP_HOTJAR_VERSION;

Hotjar.init(siteId, hotjarVersion);
