import { createStore } from "vuex";
import Cookies from "js-cookie";
import axios from "axios";

const state = {
  user: null,
  reset_password: {
    user: {
      user_email: null,
      user_id: null,
    },
    state: {
      email: true,
      otp: false,
      password: false,
    },
  },
  search: {
    result: null,
    isLoading: false,
    error: null,
  },
  searchSelectionCategory: -1,
  products: [],
  cart: [],
  carts: [],
  orders: [],
  user_race: {
      contact: {
          full_name: null,
          email: null,
          phone: null,
          address: null,
          province: null,
          city: null,
          region: null,
      },
      participants: {
          first_name: null,
          last_name: null,
          bib_name: null,
          identity_card: null,
          blood_type: null,
          gender: null,
          date_of_birth: null,
          size_shirt: null,
          medical_condition: null,
          is_checkup: null
      },
      emergency: {
          full_name: null,
          phone: null,
          relation: null,
      },
      agreement: null,
  },
  env: process.env.NODE_ENV,
  apiBaseURL: process.env.VUE_APP_API_BASE_URL,
  opened_window: null,
  credential: null,
  countCartChange: false,
  errorPage: null
};

const store = createStore({
  state,
  getters: {
    getStateReset(state) {
      return state.reset_password.state;
    },
    getResetUser(state) {
      return state.reset_password.user;
    },
    search: (state) => state.search,
    selectionCategory: (state) => state.searchSelectionCategory,
    products: (state) => state.products,
    cart: (state) => state.cart,
    orders: (state) => state.orders,
    getWindow: (state) => state.opened_window,
  },
  actions: {
    login(_, data) {
      Cookies.remove("guest_token");

      Cookies.set("auth_token", data.auth_token);
      Cookies.set("refresh_token", data.refresh_token);

      delete data.auth_token;
      delete data.refresh_token;
      Cookies.set("user_data", JSON.stringify(data));
    },
    stateResetEmail({ commit }, boolean) {
      commit("setResetEmail", boolean);
    },
    stateResetUser({ commit }, user) {
      commit("setResetUser", user);
    },
    stateResetOTP({ commit }, boolean) {
      commit("setResetOTP", boolean);
    },
    stateResetPassword({ commit }, boolean) {
      commit("setResetPassword", boolean);
    },
    guest(_, data) {
      Cookies.set("guest_token", data);
    },
    loadUser(context, data) {
      context.commit("loadUser", data);
    },
    addItemToCart(context, item) {
      context.commit("addToCart", item);
    },
    removeItemFromCart({ commit }, id) {
      commit("removeFromCart", id);
    },
    addQty({ commit }, id) {
      commit("addQty", id);
    },
    reduceQty(context, id) {
      context.commit("reduceQty", id);
    },
    emptyCart({ commit }) {
      commit("emptyCart");
    },
    addOrders({ commit }, orders) {
      commit("addToOrders", orders);
    },
    getSearchProduct({ commit }, searchData) {
      commit("getSearchProduct", searchData);
    },
    searchSelectionCategory({ commit }, data) {
      commit("searchSelectionCategory", data);
    },
    setWindow({ commit }, data) {
      commit("setWindow", data);
    },
  },
  mutations: {
    logout(state) {
      Cookies.remove("auth_token");
      Cookies.remove("refresh_token");
      Cookies.remove("user_data");

      state.user = null;
    },
    loadUser(state, data) {
      state.user = data;
    },
    setResetEmail(state, boolean) {
      state.reset_password.state.email = boolean;
      state.reset_password.state.otp = false;
      state.reset_password.state.password = false;
    },
    setResetUser(state, user) {
      state.reset_password.user.user_id = user.user_id;
      state.reset_password.user.user_email = user.user_email;
    },
    setResetOTP(state, boolean) {
      state.reset_password.state.email = false;
      state.reset_password.state.otp = boolean;
      state.reset_password.state.password = false;
    },
    setResetPassword(state, boolean) {
      state.reset_password.state.email = false;
      state.reset_password.state.otp = false;
      state.reset_password.state.password = boolean;
    },
    addToCart(state, item) {
      const store = state.cart.find(
        (order) => order.store_id === item.store_id
      );
      if (!store) {
        state.cart.push(item);
      } else {
        const products = store.products.find(
          (product) => product.product_id === item.products[0].product_id
        );
        if (!products) {
          state.cart.products.push(item.products);
        } else {
          const product_types = products.product_types.find(
            (type) =>
              type.producttype_id ===
              item.products[0].product_types[0].producttype_id
          );
          if (!product_types) {
            state.cart[0].products[0].product_types.push(
              item.products[0].product_types[0]
            );
          }
        }
      }
    },
    addQty(state, id) {
      const product_types = state.cart[0].products[0].product_types.find(
        (types) => types.producttype_id === id
      );
      product_types.qty++;
    },
    reduceQty(state, id) {
      const product_types = state.cart[0].products[0].product_types.find(
        (types) => types.producttype_id === id
      );
      if (product_types.qty > 1) {
        product_types.qty--;
      } else {
        state.cart[0].products[0].product_types =
          state.cart[0].products[0].product_types.filter(
            (types) => types.producttype_id !== id
          );
      }
    },
    removeFromCart(state, id) {
      state.cart[0].products[0].product_types =
        state.cart[0].products[0].product_types.filter(
          (types) => types.producttype_id !== id
        );
    },
    emptyCart(state) {
      state.cart = [];
      state.orders = [];
    },
    addToOrders(state, orders) {
      const storeInOrdersState = state.orders.find(
        (store) => store.store_id === orders.store_id
      );
      if (storeInOrdersState) {
        const storeInOrdersIndex = state.orders.findIndex(
          (store) => store.store_id === orders.store_id
        );
        const productInOrdersState = state.orders[
          storeInOrdersIndex
        ].products.find((product) => product.product_id);
        if (productInOrdersState) {
          const productInOrdersIndex = state.orders[
            storeInOrdersIndex
          ].products.findIndex(
            (product) => product.product_id === orders.products[0].product_id
          );
          const productTypeInOrdersState =
            state.orders[storeInOrdersIndex].products[productInOrdersIndex];
          if (productTypeInOrdersState) {
            state.orders[storeInOrdersIndex].products[productInOrdersIndex] =
              orders.products[0];
          } else {
            state.orders[storeInOrdersIndex].products.push(orders.products[0]);
          }
        }
      } else {
        state.orders.push(orders);
      }
    },
    getSearchProduct(state, searchData) {
      state.search.isLoading = true;
      let data = searchData;
      if (state.searchSelectionCategory !== -1) {
        data["category"] = state.searchSelectionCategory;
      } else {
        delete data["category"];
      }
      axios
        .get("product", { params: data })
        .then((response) => {
          state.search.result = response.data.response;
          state.search.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          state.search.error = error.response.data.error;
        })
        .finally(() => (state.search.isLoading = false));
    },
    searchSelectionCategory(state, data) {
      state.searchSelectionCategory = data;
    },
    setWindow(state, data) {
      state.opened_window = data;
    },
    addUserRaceContactInformation(state, payload = {
        full_name: null,
        email: null,
        phone: null,
        address: null,
        province: null,
        city: null,
        region: null
    }) {
        state.user_race.contact.full_name = payload.full_name
        state.user_race.contact.email = payload.email
        state.user_race.contact.phone = payload.phone
        state.user_race.contact.address = payload.address
        state.user_race.contact.province = payload.province
        state.user_race.contact.city = payload.city
        state.user_race.contact.region = payload.region
    },
    addUserRaceParticipantsInformation(state, payload = {
        first_name: null,
        last_name: null,
        bib_name: null,
        identity_card: null,
        blood_type: null,
        gender: null,
        date_of_birth: null,
        size_shirt: null,
        medical_condition: null,
        is_checkup: null
    }) {
        state.user_race.participants.first_name = payload.first_name
        state.user_race.participants.last_name = payload.last_name
        state.user_race.participants.bib_name = payload.bib_name
        state.user_race.participants.identity_card = payload.identity_card
        state.user_race.participants.blood_type = payload.blood_type
        state.user_race.participants.gender = payload.gender
        state.user_race.participants.date_of_birth = payload.date_of_birth
        state.user_race.participants.size_shirt = payload.size_shirt
        state.user_race.participants.medical_condition = payload.medical_condition
        state.user_race.participants.is_checkup = payload.is_checkup
    },
    addUserRaceEmergencyInformation(state, payload = {
        full_name: null,
        phone: null,
        relation: null
    }) {
        state.user_race.emergency.full_name = payload.full_name
        state.user_race.emergency.phone = payload.phone
        state.user_race.emergency.relation = payload.relation
    },
  },
});

export default store;
