// Styles
import "@mdi/font/css/materialdesignicons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@/plugins/main.scss";
import "vuetify/styles";
import "vuetify/dist/vuetify.min.css";

// Vuetify
import { createVuetify } from "vuetify";

import { aliases, mdi } from "vuetify/iconsets/mdi";
import { aliases as FaAliases, fa } from "vuetify/iconsets/fa";

import { id, en } from "vuetify/locale";

export default createVuetify({
  theme: {
    options: {
      customProperties: true,
    },
    defaultTheme: "customTheme",

    defaultAssets: {
      font: false,
    },
    themes: {
      customTheme: {
        // dark: false,
        colors: {
          primary: "#0854D7",
          "primary-container": "#DBE1FF",
          "bg-primary-container": "#00174B",
          "primary-dark": "#B4C5FF",
          "bg-primary-dark": "#002A77",
          "primary-container-dark": "#003EA7",
          "bg-primary-container-dark": "#DBE1FF",
          "primary-50": "#376EF2",
          "primary-60": "#608BFF",
          "primary-70": "#8BA8FF",
          "primary-95": "#EEF0FF",
          secondary: "#A33E00",
          "secondary-container": "#FFDBCD",
          "bg-secondary-container": "#360F00",
          "secondary-dark": "#FFB596",
          "bg-secondary-dark": "#581E00",
          "secondary-container-dark": "#7C2E00",
          "bg-secondary-container-dark": "#FFDBCD",
          "secondary-50": "#CB5000",
          "secondary-60": "#F06619",
          "secondary-70": "#FF8C56",
          tertiary: "#365AAF",
          "tertiary-container": "#DAE2FF",
          "bg-tertiary-container": "#001848",
          "tertiary-dark": "#B2C5FF",
          "bg-tertiary-dark": "#002B73",
          "tertiary-container-dark": "#184295",
          "bg-tertiary-container-dark": "#DAE2FF",
          "tertiary-50": "#5174CA",
          "tertiary-60": "#6C8EE5",
          "tertiary-70": "#89A9FF",
          error: "#BA1A1A",
          "error-container": "#FFDAD6",
          "bg-error-container": "#410002",
          "error-dark": "#FFB4AB",
          "bg-error-dark": "#690005",
          "error-container-dark": "#93000A",
          "bg-error-container-dark": "#FFDAD6",
          "error-50": "#DE3730",
          "error-60": "#FF5449",
          "error-70": "#FF897D",
          background: "#FEFBFF",
          "bg-background": "#1B1B1F",
          surface: "#FEFBFF",
          "bg-surface": "#1B1B1F",
          "background-dark": "#1B1B1F",
          "bg-background-dark": "#E4E2E6",
          "surface-dark": "#1B1B1F",
          "bg-surface-dark": "#C7C6CA",
          "neutral-20": "#303034",
          "neutral-50": "#77767A",
          "neutral-95": "#F2F0F4",
          "surface-variant": "#E2E2EC",
          "bg-surface-variant": "#45464F",
          outline: "#757680",
          "surface-variant-dark": "#45464F",
          "bg-surface-variant-dark": "#C5C6D0",
          "outline-dark": "#8F909A",
          "neutral-variant-10": "#191B23",
          "neutral-variant-20": "#2E3038",
          "neutral-variant-40": "#5D5E67",
          "neutral-variant-70": "#AAAAB4",
          base: "#809FB8",
          information: "#BD4640",
          "main-background": "#f8f8f8",
        },
      },
    },
  },
  locale: {
    locale: "id",
    fallback: "id",
    messages: { id, en },
  },
  icons: {
    defaultSet: "mdi",
    aliases: {
      ...FaAliases,
      ...aliases,
    },
    sets: {
      mdi,
      fa,
    },
  },
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
});
